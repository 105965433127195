import { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import parse from 'html-react-parser'

import FlexSection from '@components/FlexSection'
import FlexIcon from '@components/FlexIcon'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

SwiperCore.use([Pagination])

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_BoxWithIconAndCta3Columns,
  WpPrograma_Acfpagefields_Content_BoxWithIconAndCta3Columns
} from '@_types/graphql-types'

import Button from '@components/Button'

type contentType =
  | WpPage_Acfpagefields_Content_BoxWithIconAndCta3Columns
  | WpPrograma_Acfpagefields_Content_BoxWithIconAndCta3Columns

const BoxWithIconAndCta3Columns = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { columns, text, title, bgColor, boxColor, borders, tagTitle } = data

  const sectionColors = findColors(bgColor)
  const boxColors = findColors(boxColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      index={index}
      title={title}
      tagTitle={tagTitle}
      colors={sectionColors}
      alignTitle="center"
      id={title || ''}
      paddingBottom
      tabs={tabs}
    >
      {text && (
        <div tw="prose mx-auto mb-6 text-center!">{text && parse(text)}</div>
      )}
      <SwiperContainer>
        <Swiper
          slidesPerView={1.25}
          spaceBetween={16}
          breakpoints={{
            600: {
              slidesPerView: 2.25,
              spaceBetween: 24
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 96
            },
            1366: {
              slidesPerView: 3,
              spaceBetween: 128
            }
          }}
          pagination={{
            clickable: true
          }}
        >
          {columns &&
            columns.map((column, index) => {
              const iconColors = findColors(column.svgIcon.iconColor)
              return (
                <SwiperSlide key={index}>
                  <BoxContainer
                    borders={borders}
                    css={[boxColors && boxColors.bg]}
                  >
                    <div tw="text-center mb-6">
                      <FlexIcon
                        name={column.svgIcon.iconType}
                        css={[iconColors.svg]}
                        tw="w-16 mx-auto"
                      />
                    </div>
                    <div tw="text-center mb-2">
                      <h3 tw="text-xl font-bold text-gray-900">
                        {column.title}
                      </h3>
                    </div>
                    <div tw="mb-6">
                      <div tw="text-sm text-gray-600! dark:text-black prose prose-sm">
                        {parse(column.text)}
                      </div>
                    </div>
                    {column.cta && (
                      <div tw="text-center">
                        <Button
                          link={column.cta.url}
                          text={column.cta.title}
                          color="orange"
                        />
                      </div>
                    )}
                  </BoxContainer>
                </SwiperSlide>
              )
            })}
        </Swiper>
      </SwiperContainer>
    </FlexSection>
  )
}

type BoxContainerProps = {
  borders: string
}

const BoxContainer = styled.div<BoxContainerProps>`
  ${tw`border border-gray-100 shadow-lg pt-8 pb-4 px-4 h-full`}
  ${props => props.borders == 'rounded' && tw`rounded-4xl`}
  ${props =>
    props.borders == 'half-rounded' && tw`rounded-tl-4xl rounded-br-4xl`}
`

const SwiperContainer = styled.div`
  .swiper-wrapper {
    ${tw`items-stretch`}
  }

  .swiper-slide {
    ${tw`py-8 h-auto`}
  }

  .swiper-pagination {
    ${tw`bottom-0! z-10`}

    .swiper-pagination-bullet {
      ${tw`w-7 rounded-md h-1 bg-gray-500!`}

      &-active {
        ${tw`bg-lemann-dark-orange!`}
      }
    }
  }
`

export default BoxWithIconAndCta3Columns
